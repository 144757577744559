import React from 'react'
import {Link} from "react-router-dom";
import prothamalogo from "../images/PROTHAMA-LOGO.jpg"
import "./Navbar.css";

function Navbar() {
  return (
    <div>
      <div className='fixed-top'>
      {/* <div className='prothamalifespace_flat'>


<div className="album py-2">

<div className="container">

  <div className="row ">

    <div className="col-md-5" >
      <div className="d-flex justify-content-between align-items-center">
      


        <Link to="#">
          <button style={{ pading: "2px" }} className="btn btn-light btn-sm"> <small>Prothama Lifespace</small></button>
        </Link>
   
      </div>

    </div>

    <div className="d-flex justify-content-between align-items-center">





    </div>
  </div>
</div>
</div>
</div> */}
        <nav className="navbar navbar-expand-lg navbar-white bg-white shadow">
  <div className="container-fluid">
    <Link to="/">
   <img src={prothamalogo} className='me-2' height="42px" width="160px" alt='prothama'/>
  
   </Link>
   <p className='mt-2' style={{color:"grey"}} ><small>An ISO 9001:2015</small></p>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-1 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="nav-link active" aria-current="page" href="#">Home</Link>
        </li>
        <li className="nav-item">
          <Link to="/" className="nav-link" href="#">About Prothama</Link>
        </li>
 
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Project
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a className="dropdown-item" href="#">Current Project</a></li>
            <li><a className="dropdown-item" href="#">Completed Project</a></li>
         
          </ul>
        </li>
        <li className="nav-item">
          <Link to="#" className="nav-link" href="#">Contact Us</Link>
        </li>
      </ul>
    
   {/* <a href='tel: 9434054859'>
        <button className="buttoncallflat buttoncallflats1" type="submit">Call- +91 94340 54859 </button>
        </a> */}
     
    </div>
  </div>
</nav>
</div>
    </div>
  )
}

export default Navbar