import React from 'react'

function Mapg() {
    return (
        <div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                      
                            <iframe className="iframe-fluid" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Prothama%20Lifespace,%209FXX+7XG,%20Asannagar,%20Krishnanagar,%20West%20Bengal%20741101%20+(Prothama%20Lifespace,%209FXX+7XG,%20Asannagar,%20Krishnanagar,%20West%20Bengal%20741101)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <a href='https://www.versicherungen.at/cyber-versicherung/'></a>

                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mapg