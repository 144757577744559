
import React from 'react'
import Prothama from "../images/SLIDER-BANNAR.jpg";
import ProthamaENGLISH from "../images/BANNAR.jpg";
import ProthamaBengali from "../images/home-bannar.jpg";

function Slider() {
  return (
    <div>

<div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval="10000">
      <img src={Prothama} className="d-block w-100" alt="bestflatrajbarikrishnagar"/>
      <div className="carousel-caption d-none d-md-block">
   
      </div>
    </div>
    <div className="carousel-item" data-bs-interval="2000">
      <img src={ProthamaENGLISH} className="d-block w-100" alt="Prothama"/>
      <div className="carousel-caption d-none d-md-block">
      
      </div>
    </div>
    <div className="carousel-item">
      <img src={ProthamaBengali} className="d-block w-100" alt="Prothama"/>
      <div className="carousel-caption d-none d-md-block">

      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>



    </div>
  )
}

export default Slider