import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import ScrollToTop from "react-scroll-to-top";
import "./Home.css";
// import AddEnquiry from '../AddEnquiry';
// import Prothamakrishnagar from "../images/bannar-english.jpeg";
// import Prothamaflat from "../images/bannar-bengali.jpeg";


function Home() {
  return (
    <div>
      <Navbar />
      <br />

    

      <div className="album py-1">
        <div className="container">
          <h6>Prothama Lifespace</h6>
        </div>
      </div>
      <Slider />
      <ScrollToTop smooth top="100" color="maroon" />
      <div className='colorback'>
        <marquee><h4 className='mt-2' style={{ color: "white" }} >Welcome To Prothama | More Details Coming Soon!</h4></marquee>
      </div>
  
{/*    
      <div className="album py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-3">
            <div className='prothamalifespace'>
                <img src={Prothamakrishnagar} className="card-img-top image10" alt="Prothamakrishnagar" />
                <div className="overlay">Prothama Lifespace</div>
                <div className="card-body">
                
              
              </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
            <div className='prothamalifespace'>
                <img src={Prothamaflat} className="card-img-top image10" alt="Prothamaflat" />
                <div className="overlay">Prothama Lifespace</div>
                <div className="card-body">
  
              </div>
              </div>

            </div>
          </div>
        </div>
      </div> */}
      <br/>
      {/* <div className="prothama_life-space">

        <div className="album py-0">
          <div className="container">
            <div className="row ">
              <div className="col-md-7" >
                <br />
                <br />
                <h1 className='mb-2'><b>PROTHAMA Life Space</b></h1>
                <h3 style={{ color: "white" }}><b>20+ Facility, Best Conectivity</b></h3>



              </div>
              <div className="col-md-5" >
                <br />
                <br />

              </div>
            </div>
          </div>
        </div>
      </div> */}
   


    </div>
  )
}

export default Home